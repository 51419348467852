/* eslint-disable @typescript-eslint/no-inferrable-types */
import { JsonObject, JsonProperty, JsonConverter, JsonCustomConvert, Any } from "json2typescript";
import { environment } from "environments/environment";
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { DatabaseService } from 'app/services/database.service';
import { catchError, finalize } from 'rxjs/operators';
import { UserPref } from "./userPref.model";
import { SubscriptionType } from "./subscription.model";

@JsonObject('Stripe')
export class Stripe {
    @JsonProperty("CustomerId", String, true)
    CustomerId: string = '';
    @JsonProperty("SubscriptionId", String, true)
    SubscriptionId: string = '';
    @JsonProperty("ClientSecret", String, true)
    ClientSecret: string = '';
    @JsonProperty("Type", Number, true)
    Type: SubscriptionType = SubscriptionType.Free;

}

@JsonObject('Connection')
export class Connection {
    @JsonProperty("Connected", Boolean, true)
    public Connected: boolean = false;
    @JsonProperty("Disconnect", Boolean, true)
    public Disconnect: boolean = false;
    @JsonProperty("DisconnectBy", String, true)
    public DisconnectBy: string = "";
    @JsonProperty("LastOnline", Object, true)
    public LastOnline: any;
}

export enum UserType {
	User = 'User',
	Admin = 'Admin',
	Device = 'Device',
	MyUser = 'MyUser',
	ShareLib = 'ShareLib'
}

export enum UserSpecific {
	SendStory = 'SendStory',
    MyUsers = 'MyUsers',
    GenerateContent = 'GenerateContent',
    Analytics = 'Analytics',
    RemoveStream = 'RemoveStream',
    ForceDataLow = 'ForceDataLow',
    VoiceRecognition = 'VoiceRecognition',
	Interface = 'Interface',
	SharedLibMaster = 'SharedLibMaster',
	OldEvent = 'OldEvent',
}

@JsonObject("UserProject")
export class UserProject {

	@JsonProperty("Projects", [String], true)
	Projects : string [] = [];
	@JsonProperty("ProjectsV2", [String], true)
	ProjectsV2 : string [] = [];
	@JsonProperty("Scenarios", [String], true)
	Scenarios : string [] = [];
	@JsonProperty("Diffusions", [String], true)
	Diffusions : string [] = [];
	@JsonProperty("Library", String, true)
	Library : string = "";
	@JsonProperty("Interface", String, true)
	Interface : string = "";
	@JsonProperty("Devices", [String], true)
	Devices : string[] = [];
	@JsonProperty("Categories", [String], true)
	Categories : string[] = [];
    @JsonProperty("Subscriptions", [String], true)
	Subscriptions : string[] = [];
    @JsonProperty("MyUsers", [String], true)
	MyUsers : string[] = [];




	constructor() {
		this.Projects[0]="";
		this.Library = "";
		this.Interface = "";
		this.ProjectsV2 = [];
		this.Scenarios = [];
		this.Devices = [];
		this.Categories = [];
        this.Subscriptions = [];
        this.MyUsers = [];
	}
}

@JsonConverter
class DateConverter implements JsonCustomConvert<Date> {

	// Object -> Json
	serialize(date: Date): string {
		//correction pour pb sur Safari MAC OS
		let month :string="";
		month = (date.getMonth()+1).toString();
		month = (month.length<2) ? "0"+month : month;
		let day :string="";
		day = (date.getDate()).toString();
		day = (day.length<2) ? "0"+day : day;

		return date.getFullYear() + "-" + month + "-" +  day;
	}

	// Json -> Object
	deserialize(date: string): Date {
		//correction pour pb sur Safari MAC OS
		const dt=date.split("-");
		return new Date(parseInt(dt[0]),parseInt(dt[1])-1,parseInt(dt[2]));
	}

}

@JsonConverter
class DateHourConverter implements JsonCustomConvert<Date> {

	// Object -> Json
	serialize(date: Date): number {
		return date.getTime();
	}

	// Json -> Object
	deserialize(date: number): Date {
		return new Date(date);
	}

}

@JsonObject("User")
export class User {

	@JsonProperty("Key", String, true)
	Key: string = "";
	@JsonProperty("Status", Number, true)
	Status: number = -1;
	@JsonProperty("Email", String, true)
    Email: string = "";
	@JsonProperty("Username", String, true)
    Username: string = "";
	@JsonProperty("FirstName", String, true)
    FirstName: string = "";
	@JsonProperty("LastName", String, true)
    LastName: string = "";
	@JsonProperty("Versions", [UserProject], true)
	Versions: UserProject[] = [];
	@JsonProperty("Version", Number, true)
	Version: number = 1;
	@JsonProperty("DevicePassword", String, true)
	DevicePassword: string = "";
	@JsonProperty("DiskLimit", Number, true)
	DiskLimit: number = 1;
	@JsonProperty("DiskUsage", Number, true)
	DiskUsage: number = 0;
	@JsonProperty("StartDate", DateConverter, true)
	StartDate: Date = new Date();
	@JsonProperty("EndDate", DateConverter, true)
	EndDate: Date = new Date("2035-1-1");
	@JsonProperty("Type", String, true)
	Type: UserType = UserType.User;
	@JsonProperty("CreatedBy", String, true)
	CreatedBy: string = "";
	@JsonProperty("CreatorLibrary", String, true)
	CreatorLibrary: string = "";
	@JsonProperty("LastConnexion", DateHourConverter, true)
	LastConnexion: Date = new Date(0);
	@JsonProperty("SpecificData", [String], true)
	SpecificData : UserSpecific [] = [];
	@JsonProperty("UserPref", UserPref, true)
	UserPref: UserPref = new UserPref();
	@JsonProperty("Connection", Connection, true)
    Connection: Connection = new Connection();
	@JsonProperty("DefaultLang", String, true)
    DefaultLang: string = "fr";

	@JsonProperty("Languages", [String], true)
    Languages: string[] = [];

    @JsonProperty("DoNotShow", [String], true)
    DoNotShow: string[] = [];

    @JsonProperty("Stripe", Stripe, true)
    Stripe: Stripe = new Stripe();


	constructor() {

		this.Versions=[];
		const version = parseInt(environment.projectVersion);
		this.Version = version;
		for(let i =0; i<= version;i++) {
			this.Versions[i] = new UserProject();
			this.Versions[i].Projects[0] = "";
			this.Versions[i].Library = "";
			this.Versions[i].Interface = "";
		}

	}





    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
	getKey() 	        	: string { return this.Key; }
	getCreatedBy() 	       	: string { return this.CreatedBy; }
	getCreatorLibrary() 	: string { return this.CreatorLibrary; }
	getStatus() 			: number { return this.Status; }
	getEmail() 	        	: string { return this.Email; }
	getUsername() 			: string { return this.Username; }
	getFirstName() 			: string { return this.FirstName; }
	getLastName() 			: string { return this.LastName; }
	getDevicePassword() 	: string { return this.DevicePassword; }
	getProjects() 			: string[] { return this.Versions[this.Version].Projects; }
	getProjectsV2() 		: string[] { return this.Versions[this.Version].ProjectsV2; }
	getLibrary(forceLib=false) 			: string { return (this.Type==UserType.ShareLib&&!forceLib)?this.CreatorLibrary:this.Versions[this.Version].Library; }
	getInterface() 			: string { return this.Versions[this.Version].Interface; }
	getScenarios() 			: string[] { return this.Versions[this.Version].Scenarios; }
	getDiffusions() 		: string[] { return this.Versions[this.Version].Diffusions; }
	getType()				: UserType { return this.Type; }
	getVersion()			: number { return this.Version; }
	getStartDate()			: Date { return this.StartDate; }
	getEndDate()			: Date { return this.EndDate; }
	getDiskLimit()			: number { return this.DiskLimit; }
	getDiskUsage()			: number { return this.DiskUsage; }
	getLastConnexion()		: Date { return this.LastConnexion; }
	getSpecific() 			: UserSpecific[] { return this.SpecificData; }


	// -----------------------------------------------------------------------------------------------------
	// @ SETTER
	// -----------------------------------------------------------------------------------------------------
	setKey(Key: string) 						{ this.Key = Key; }
	setCreatedBy(CreatedBy: string) 			{ this.CreatedBy = CreatedBy; }
	setCreatorLibrary(Library: string) 			{ this.CreatorLibrary = Library; }
	setStatus(Status: number) 					{ this.Status = Status; }
	setEmail(Email: string) 					{ this.Email = Email; }
	setUsername(Username: string) 				{ this.Username = Username; }
	setFirstName(FirstName: string) 			{ this.FirstName = FirstName; }
	setLastName(LastName: string) 				{ this.LastName = LastName; }
	setDevicePassword(DevicePassword: string)	{ this.DevicePassword = DevicePassword; }
	setProjects(Projects: string[]) 			{ this.Versions[this.Version].Projects = Projects; }
	setProjectsV2(ProjectsV2: string[]) 		{ this.Versions[this.Version].Projects = ProjectsV2; }
	setLibrary(Library: string) 				{ this.Versions[this.Version].Library = Library; }
	setInterface(Interface: string) 			{ this.Versions[this.Version].Interface = Interface; }
	setType(Type: UserType)						{ this.Type = Type; }
	setVersion(Version: string)					{ this.Version = parseInt(Version); }
	setStartDate(StartDate: Date)				{ this.StartDate = StartDate; }
	setEndDate(EndDate: Date)					{ this.EndDate = EndDate; }
	setDiskLimit(DiskLimit: number) 			{ this.DiskLimit = DiskLimit; }
	setDiskUsage(DiskUsage: number) 			{ this.DiskUsage = DiskUsage; }
	setLastConnexion(LastConnexion: Date)		{ this.LastConnexion = LastConnexion; }
	setSpecific(spec: UserSpecific[]) 			{ this.SpecificData=spec;}

	// -----------------------------------------------------------------------------------------------------
	// @ PUBLIC METHODS
	// -----------------------------------------------------------------------------------------------------
	addProject(key: string) {
		if(this.Versions[this.Version]==undefined){
			this.Versions[this.Version] = new UserProject();
		}
		this.Versions[this.Version].Projects[0]=key;
	}
	addProjectV2(key: string) {
		if(this.Versions[this.Version]==undefined){
			this.Versions[this.Version] = new UserProject();
		}
		this.Versions[this.Version].ProjectsV2.push(key);
	}
	addScenario(key: string) {
		if(this.Versions[this.Version]==undefined){
			this.Versions[this.Version] = new UserProject();
		}
		if(this.Versions[this.Version].Scenarios==undefined){
			this.Versions[this.Version].Scenarios = [];
		}

		this.Versions[this.Version].Scenarios.push(key);
	}

	addDiffusion(key: string) {
		if (this.Versions[this.Version] == undefined){
			this.Versions[this.Version] = new UserProject();
		}
		this.Versions[this.Version].Diffusions.push(key);
	}

	addCategorie(key: string) {
		if(this.Versions[this.Version]==undefined){
			this.Versions[this.Version] = new UserProject();
		}
		this.Versions[this.Version].Categories.push(key);
	}
	removeProjectV2(key:string){
		if(this.Versions[this.Version]==undefined){
			return;
		}
		for (let i = 0; i < this.Versions[this.Version].ProjectsV2.length; i++) {
			if(this.Versions[this.Version].ProjectsV2[i]==key){
				this.Versions[this.Version].ProjectsV2.splice(i,1);
				return;
			}
		}
	}
	removeScenario(key:string){
		if(this.Versions[this.Version]==undefined){
			return;
		}
		for (let i = 0; i < this.Versions[this.Version].Scenarios.length; i++) {
			if(this.Versions[this.Version].Scenarios[i]==key){
				this.Versions[this.Version].Scenarios.splice(i,1);
				return;
			}
		}
	}

	removeDiffusion(key:string){
		if(this.Versions[this.Version]==undefined){
			return;
		}
		for (let i = 0; i < this.Versions[this.Version].Diffusions.length; i++) {
			if(this.Versions[this.Version].Diffusions[i]==key){
				this.Versions[this.Version].Diffusions.splice(i,1);
				return;
			}
		}
	}

	addDevice(key: string) {
		if(this.Versions[this.Version]==undefined){
			this.Versions[this.Version] = new UserProject();
		}
		this.Versions[this.Version].Devices.push(key);
	}
	deleteDevice(key: string){
		if(this.Versions[this.Version]==undefined){
			return;
		}
		for (let i = 0; i < this.Versions[this.Version].Devices.length; i++) {
			if(this.Versions[this.Version].Devices[i]==key){
				this.Versions[this.Version].Devices.splice(i,1);
				return;
			}
		}
	}
	addLibrary(key: string) {

		this.Versions[this.Version].Library=key;
	}

	addInterface(key: string) {

		this.Versions[this.Version].Interface=key;
	}


	getStoryCreatedNumber():number
	{
		if(this.Versions[this.Version]==undefined){
			return;
		}
		else
		{
			if(this.Version<3)
				return 0;
			else
			{
				if(this.Versions[this.Version].Scenarios!=undefined)
					return this.Versions[this.Version].Scenarios.length;
				else
					return 0;
			}
		}
	}

	getStoryPublishedNumber():number
	{
		if(this.Versions[this.Version]==undefined){
			return;
		}
		else
		{
			if(this.Version<3)
				return 0;
			else
			{
				if(this.Versions[this.Version].Diffusions!=undefined)
					return this.Versions[this.Version].Diffusions.length;
				else
					return 0;
			}
		}
	}
    getBoxCreatedNumber():number
    {
        if(this.Versions[this.Version]==undefined){
			return;
		}
		else
		{
			if(this.Version<3)
				return 0;
			else
			{
				if(this.Versions[this.Version].Devices!=undefined)
					return this.Versions[this.Version].Devices.length;
				else
					return 0;
			}
		}
    }
    getUserCreatedNumber():number
    {
        if(this.Versions[this.Version]==undefined){
			return;
		}
		else
		{
			if(this.Version<3)
				return 0;
			else
			{
				if(this.Versions[this.Version].MyUsers!=undefined)
					return this.Versions[this.Version].MyUsers.length;
				else
					return 0;
			}
		}
    }
}

export class UserListDataSource extends DataSource<User> {

    private usersSubject = new BehaviorSubject<User[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading = this.loadingSubject.asObservable();

    constructor(private _databaseService: DatabaseService) {
		super();
	}

    connect(collectionViewer: CollectionViewer): Observable<User[]> {

        return this.usersSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.usersSubject.complete();
        this.loadingSubject.complete();
    }

	loadUsers(	sortDirection : string,
				pageIndex : number,
				pageSize : number ) {

        this.loadingSubject.next(true);

		this._databaseService.findAdminUsersList(sortDirection,pageIndex,pageSize)
			.pipe(
				catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
			).subscribe(users => {this.usersSubject.next(users);});
	}

}


