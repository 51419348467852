import { JsonObject, JsonProperty } from "json2typescript";
import { Scenario } from './scenario/scenario.model';

export class MosaicProject {

	width : number = 0;
	height : number = 0;
	url : string = "";
	class : string = "";
	

	constructor() {
		this.width=0;
		this.height=0;
		this.url = "";
		this.class = "";
	}
	
}

@JsonObject("Project")
export class ProjectV2 {

	@JsonProperty("Key", String, true)
	Key: string = "";
	@JsonProperty("Name", String, true)
	Name: string = "";
	@JsonProperty("Contents", [String], true)
	Contents : string[] = [];
	@JsonProperty("LastUpdate", String, true)
	LastUpdate: string = Date.now().toString();
	PreviewURL: string ="";
	Mosaic: MosaicProject[] = [];
	@JsonProperty("Scenarios", [String], true)
	private Scenarios: string[] = [];
	@JsonProperty("Diffusions", [String], true)
	private Diffusions: string[] = [];


	constructor() {}

	// -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
    // -----------------------------------------------------------------------------------------------------
	get scenarios(): string[] { return this.Scenarios; }
    set scenarios(value: string[]) { this.Scenarios = value; }

	get diffusions(): string[] { return this.Diffusions; }
    set diffusions(value: string[]) { this.Diffusions = value; }

	// -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
	getKey() 			: string { return this.Key; }
	getName() 			: string { return this.Name; }
	getContents() 		: string[] { return this.Contents; }
	getScenarios() 		: string[] { return this.Scenarios; }
	getLastUpdate() 	: string { return this.LastUpdate; }
	getPreviewURL() 	: string { return this.PreviewURL; }
	getMosaic() 		: MosaicProject[] { return this.Mosaic; }

	// -----------------------------------------------------------------------------------------------------
	// @ SETTER
	// -----------------------------------------------------------------------------------------------------
	setKey(key: string) 							{ this.Key = key; }
	setName(Name: string) 							{ this.Name = Name; }
	setContents(Contents:  string[])				{ this.Contents = Contents; }
	setScenarios(Scenarios:  string[])				{ this.Scenarios = Scenarios; }
	setLastUpdate(lastUpdate: string) 				{ this.LastUpdate = lastUpdate; }
	setPreviewURL(PreviewURL: string) 				{ this.PreviewURL = PreviewURL; }
	setMosaic(Mosaic: MosaicProject[]) 				{ this.Mosaic = Mosaic; }

	// -----------------------------------------------------------------------------------------------------
	// @ PUBLIC METHODS
	// -----------------------------------------------------------------------------------------------------
	
	/**
	 * Add scenario to scenarios list 
	 * 
	 * @param scenario 
	 */
	addScenario(scenario: string) {
		this.Scenarios.push(scenario);
	}

	addContent(content: string) {
		this.Contents.push(content);
   	}

   	changeContent(content: string) {
		this.Contents[this.Contents.indexOf(content, 0)] = content;
   	}

   	deleteContent(content: string) {
		this.Contents.splice(this.Contents.indexOf(content, 0), 1);
	}
	
}

