import { Scenario } from 'app/models/scenario/scenario.model';
import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";

export class DiffusionTable{
    Scenario: Scenario;
    Diffusion: Diffusion;
}
export enum ModeType {
    Public = "Public",
    Private = "Private",
    Auth = "Auth",
}

@JsonObject("Diffusion")
export class Diffusion {

    @JsonProperty("Key", String, true)
    private Key: string = "";
    @JsonProperty("Web", Boolean, true)
    private Web: boolean = false;
    @JsonProperty("Box", Boolean, true)
    private Box: boolean = false;
    @JsonProperty("Private", Boolean, true)
    private Private: boolean = true;
    @JsonProperty("Mode", String, true)
    private Mode: ModeType = ModeType.Public;
    @JsonProperty("Password", String, true)
    private Password: string = "";
    @JsonProperty("ContentKeys", [String], true)
    private ContentKeys: string[] = [];
    @JsonProperty("LibraryKey", String, true)
    private LibraryKey: string = "";
    @JsonProperty("Url", String, true)
    private Url: string = "";
    @JsonProperty("Scenario", Scenario, true)
    private Scenario: Scenario;
    @JsonProperty("Description", String, true)
    private Description: string = "";
    @JsonProperty("IsDefaultWeb", Boolean, true)
    private IsDefaultWeb: boolean = false;
    @JsonProperty("IsDefaultBox", Boolean, true)
    private IsDefaultBox: boolean = false;
    @JsonProperty("LastUpdate", String, true)
    private LastUpdate: string = "";
    @JsonProperty("EndDate", Number, true)
    private EndDate: number = undefined;
    @JsonProperty("EmailLastDay", Boolean, true)
    private EmailLastDay: boolean = false;
    @JsonProperty("Email", String, true)
    private Email: string = '';
    //@JsonProperty("Tags", [String], true)
    //Tags: string[] = []; 
    @JsonProperty("Lang", String, true)
    private Lang: string = "fr";
    @JsonProperty("Review", Boolean, true)
    private Review: boolean = false;
    @JsonProperty("LocalStorage", Boolean, true)
    private LocalStorage: boolean = false;
    

    constructor() {}
    
    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
	// -----------------------------------------------------------------------------------------------------

    get key() : string { return this.Key; }
    set key(value: string) { this.Key = value; }

    get box() : boolean { return this.Box; }
    set box(value: boolean) { this.Box = value; }

    get web() : boolean { return this.Web; }
    set web(value: boolean) { this.Web = value; }

    get private() : boolean { return this.Private; }
    set private(value: boolean) { this.Private = value; }

    get review() : boolean { return this.Review; }
    set review(value: boolean) { this.Review = value; }
    
    get password() : string { return this.Password; }
    set password(value: string) { this.Password = value; }
    
    get contentKeys() : string[] { return this.ContentKeys; }
    set contentKeys(value: string[]) { this.ContentKeys = value; }

    //get tags() : string[] { return this.Scenario.getTags(); }
   // set tags(value: string[]) { this.Scenario.setTags(value); }
    
    get libraryKey() : string { return this.LibraryKey; }
    set libraryKey(value: string) { this.LibraryKey = value; }
    
    get url() : string { return this.Url; }
    set url(value: string) { this.Url = value; }
    
    get scenario() : Scenario { return this.Scenario; }
    set scenario(value: Scenario) { this.Scenario = value; }
    
    get description() : string { return this.Description; }
    set description(value: string) { this.Description = value; }

    get mode() : ModeType { return this.Mode; }
    set mode(value: ModeType) { this.Mode = value; }

    get isDefaultWeb() : boolean { return this.IsDefaultWeb; }
    set isDefaultWeb(value: boolean) { this.IsDefaultWeb = value; }

    get isDefaultBox() : boolean { return this.IsDefaultBox; }
    set isDefaultBox(value: boolean) { this.IsDefaultBox = value; }
    
    public get lastUpdate(): string {
        return this.LastUpdate;
    }
    public set lastUpdate(value: string) {
        this.LastUpdate = value;
    }

    get endDate() : Date { return new Date(this.EndDate); }
    set endDate(value: Date) { this.EndDate = value.getTime(); }

    get email() : string { return this.Email; }
    set email(value: string) { this.Email = value; }
    
    get emailLastDay() : boolean { return this.EmailLastDay; }
    set emailLastDay(value: boolean) { this.EmailLastDay = value; }

    get lang() : string { return this.Lang; }
    set lang(value: string) { this.Lang = value; }

    get localStorage() : boolean { return this.LocalStorage; }
    set localStorage(value: boolean) { this.LocalStorage = value; }        
    // -----------------------------------------------------------------------------------------------------
	// @ Public methods
    // -----------------------------------------------------------------------------------------------------
   
}

@JsonObject("DiffusionsExamples")
export class DiffusionsExamples {
    @JsonProperty("list", [String], true)
    public list: string[] = [];
}

