import { Injectable } from '@angular/core';

import { ProjectV2, MosaicProject } from 'app/models/projectV2.model';

import { Library } from 'app/models/library.model';
//import { DatabaseService } from './database.service';

@Injectable({
  	providedIn: 'root'
})
export class ProjectV2Service {
	
    projects: ProjectV2[] = new Array();
	private _databaseService;

    constructor(
	) {}

    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
    getProjects() : ProjectV2[] {
        return this.projects;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ SETTER
    // -----------------------------------------------------------------------------------------------------
    setProjects(projects: ProjectV2[]) {
        this.projects = projects;
        if(projects == undefined)
            return;
        //GET DOWNLOAD URL !
        for (let index = 0; index < projects.length; index++) {
            const project = projects[index];            
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    createNewProject(databaseService = undefined) {
		if(databaseService != undefined){
            this._databaseService= databaseService;
        }
        // Project
        if(this.projects==undefined)
			this.projects = [];
        var newProject = new ProjectV2();
        newProject.setKey(this._databaseService.generateKey());
        //this.projects.push(newProject);
        return newProject.getKey();
    }

    deleteProject(projectKey:string){
		if(this.projects!=undefined)
        for (let i = 0; i < this.projects.length; i++) {
            if(projectKey == this.projects[i].getKey()){
                this.projects.splice(i,1);
                return;
            }
            
        }
    }
    getProject(projectKey:string){
		if(this.projects!=undefined)
        for (let i = 0; i < this.projects.length; i++) {
            if(projectKey == this.projects[i].getKey()){
                return this.projects[i];
            }
            
        }
        return null;
    }

    // === CONTENTS === //
    /*getContents() : Content[] {
        if (this.project != undefined) {
            return this.project.getContents();
        }
        return [];
    }*/
    UpdateMosaic(Contents :String[], library:Library){
		let nbLines = 2;
		let nbCols = 4;
		let Mosaic = new Array<MosaicProject>();
		let width = 1;
		let height = 1;
		if(Contents.length<2){
			height = 2;
			width = 4;
			let mosaic = new MosaicProject();
			mosaic.height=height;
			let c = library.getContent(Contents[0]);
			if(c ==null)
			{
				mosaic.url="";
			}
			else{
				mosaic.url=c.getPreviewUrl();
			}
			mosaic.class = " top-left top-right bottom-left bottom-right";
			mosaic.width=width;
			// console.log(mosaic);
			Mosaic.push(mosaic);
			return Mosaic;
		}

		let limit = Math.min(Contents.length,nbCols*nbLines);
		let l1 =  Math.floor(limit/nbLines);
		let l2 =  limit - l1;
		let nbItemL2 =  Math.max(l1,l2);
		let nbItemL1 =  Math.min(l1,l2);
		width = 1;
		let cumulWidthL2=0;
		let cumulWidthL1=0;
		for (let i = 0; i < limit; i++) {
			let mosaic = new MosaicProject();
			let c = library.getContent(Contents[i]);
			if(c ==null)
			{
				mosaic.url="";
			}
			else{
				mosaic.url=c.getPreviewUrl();
			}
			mosaic.height=height;
			if(i<nbItemL1){
				width=Math.floor(nbCols/nbItemL1);
				cumulWidthL1+=width
				if(i==nbItemL1-1 && cumulWidthL1 != 4)
				{
					Mosaic[0].width+=nbCols-cumulWidthL1;
				}
			}else{
				width=Math.floor(nbCols/nbItemL2);
				cumulWidthL2+=width; 
				
				if(i==limit-1 && cumulWidthL2 != 4){
					
					Mosaic[nbItemL1].width+=(nbCols-cumulWidthL2);
					cumulWidthL2+=(nbCols-cumulWidthL2);

				}
			}
			mosaic.width=width;
			mosaic.class = ""
			if(i==0){
				mosaic.class += " top-left";
			}
			if(i==nbItemL1-1){
				mosaic.class += " top-right";
			}
			if(i==nbItemL1){
				mosaic.class += " bottom-left";
			}
			if(cumulWidthL2==nbCols){
				mosaic.class += " bottom-right";
			}
			Mosaic.push(mosaic);
		}
		return Mosaic;
	}
}