/* eslint-disable @typescript-eslint/no-inferrable-types */
import { JsonObject, JsonProperty } from "json2typescript";

export enum PairingType {
    'Tablet','Headset','TabletControl','Other'
}

export enum PairingMode {
    'Autonome','Control'
}




@JsonObject("DeviceSettingsInfo")
export class DeviceSettingsInfo {

    @JsonProperty("GazeTime", Number, true)
    GazeTime: number = 0;
    
    @JsonProperty("MaxScaleGaze", Number, true)
    MaxScaleGaze: number = 0;
   
    @JsonProperty("MinScaleGaze", Number, true)
    MinScaleGaze: number = 0;
    
    @JsonProperty("DeviceSleepDelay", Number, true)
    DeviceSleepDelay: number = 0;
   
    @JsonProperty("RestartStory", Boolean, true)
    RestartStory: Boolean = false;
   
    @JsonProperty("AutoCanConnectToNetwork", Boolean, true)
    AutoCanConnectToNetwork: Boolean = false;
    
    @JsonProperty("UiForCalibration", Boolean, true)
    UiForCalibration: Boolean = false;
    
    @JsonProperty("DetectManette", Boolean, true)
    DetectManette: Boolean = false;
    
    @JsonProperty("DeviceRestartTime", Number, true)
    DeviceRestartTime: number = 0;
    
    public get gazeTime(): number {
        return this.gazeTime;
    }
    public set gazeTime(value: number) {
        this.gazeTime = value;
    }

    public get maxScaleGaze(): number {
        return this.maxScaleGaze;
    }
    public set maxScaleGaze(value: number) {
        this.maxScaleGaze = value;
    }

    public get minScaleGaze(): number {
        return this.minScaleGaze;
    }
    public set minScaleGaze(value: number) {
        this.minScaleGaze = value;
    }

    public get deviceSleepDelay(): number {
        return this.DeviceSleepDelay;
    }
    public set deviceSleepDelay(value: number) {
        this.deviceSleepDelay = value;
    }

    public get restartStory(): Boolean {
        return this.RestartStory;
    }
    public set restartStory(value: Boolean) {
        this.RestartStory = value;
    }

    public get autoCanConnectToNetwork(): Boolean {
        return this.AutoCanConnectToNetwork;
    }
    public set autoCanConnectToNetwork(value: Boolean) {
        this.AutoCanConnectToNetwork = value;
    }

    public get uiForCalibration(): Boolean {
        return this.UiForCalibration;
    }
    public set uiForCalibration(value: Boolean) {
        this.UiForCalibration = value;
    }

    public get detectManette(): Boolean {
        return this.DetectManette;
    }
    public set detectManette(value: Boolean) {
        this.DetectManette = value;
    }

    public get deviceRestartTime(): number {
        return this.DeviceRestartTime;
    }
    public set deviceRestartTime(value: number) {
        this.DeviceRestartTime = value;
    }
}

@JsonObject("DataUpdated")
export class DataUpdated {
    @JsonProperty("Name", String, true)
	Name: string = "";
    @JsonProperty("AvailableSpace", Number, true)
	AvailableSpace: number = 0;
    @JsonProperty("LastConnexion", Number, true)
	LastConnexion: number = 0;
    @JsonProperty("BatteryLevel", Number, true)
    BatteryLevel: number = 0;
    @JsonProperty("Mode", Number, true)
    Mode: PairingMode = PairingMode.Autonome;
    @JsonProperty("Lock", Boolean, true)
    Lock: boolean = false;
    @JsonProperty("Group", String, true)
    Group: string = "";
    @JsonProperty("UserAdm", Boolean, true)
    UserAdm: boolean = true;
    @JsonProperty("LastUpdate", Number, true)
    LastUpdate: number = 0;
    @JsonProperty("Detect", Boolean, true)
    Detect: boolean = false;
    @JsonProperty("Online", Boolean, true)
    Online: boolean = false;
    @JsonProperty("Reboot", Boolean, true)
    Reboot: boolean = false;
    @JsonProperty("ShutOff", Boolean, true)
    ShutOff: boolean = false;
    

    public get name(): string {
        return this.Name;
    }
    public set name(value: string) {
        this.Name = value;
    }

    public get availableSpace(): number {
        return this.AvailableSpace;
    }
    public set availableSpace(value: number) {
        this.AvailableSpace = value;
    }
    public get lastConnexion(): number {
        return this.LastConnexion;
    }
	public set lastConnexion(value: number) {
        this.LastConnexion = value;
    }

    public get batteryLevel(): number {
        return this.BatteryLevel;
    }
    public set batteryLevel(value: number) {
        this.BatteryLevel = value;
    }
    public get lock(): boolean {
        return this.Lock;
    }
    public set lock(value: boolean) {
        this.Lock = value;
    }

    public get userAdm(): boolean {
        return this.UserAdm;
    }
    public set userAdm(value: boolean) {
        this.UserAdm = value;
    }

    public get lastUpdate(): number {
        return this.LastUpdate;
    }
    public set lastUpdate(value: number) {
        this.LastUpdate = value;
    }
    public get mode(): PairingMode {
        return this.mode;
    }
    public set mode(value: PairingMode) {
        this.mode = value;
    }
    
    public get group(): string {
        return this.group;
    }
    public set group(value: string) {
        this.group = value;
    }

    public get detect(): boolean {
        return this.Detect;
    }
    public set detect(value: boolean) {
        this.Detect = value;
    }

    public get online(): boolean {
        return this.Online;
    }
    public set online(value: boolean) {
        this.Online = value;
    }

   
    public get reboot(): boolean {
        return this.Reboot;
    }
    public set reboot(value: boolean) {
        this.Reboot = value;
    }

    public get shutOff(): boolean {
        return this.ShutOff;
    }
    public set shutOff(value: boolean) {
        this.ShutOff = value;
    }
}

@JsonObject("DevicePairing")
export class DevicePairing {

	@JsonProperty("Guid", String, true)
    Guid: string = "";
    @JsonProperty("Model", String, true)
	Model: string = "";
    @JsonProperty("Version", String, true)
	Version: string = "";
	@JsonProperty("User", String, true)
	User: string = "";
    @JsonProperty("Type", Number, true)
	Type: PairingType = PairingType.Tablet;
    @JsonProperty("DeviceSettingsInfo", DeviceSettingsInfo, true)
    DeviceSettingsInfo: DeviceSettingsInfo = new DeviceSettingsInfo();
    @JsonProperty("SerialNumber", String, true)
	SerialNumber: string = "";
    @JsonProperty("Data", DataUpdated, true)
    Data: DataUpdated = new DataUpdated();

	constructor() {}
    public get guid(): string {
        return this.Guid;
    }
    public set guid(value: string) {
        this.Guid = value;
    }

    public get model(): string {
        return this.Model;
    }
    public set model(value: string) {
        this.Model = value;
    }

    public get version(): string {
        return this.Version;
    }
    public set version(value: string) {
        this.Version = value;
    }

    public get user(): string {
        return this.User;
    }
    public set user(value: string) {
        this.User = value;
    }

    public get type(): PairingType {
        return this.Type;
    }
    public set type(value: PairingType) {
        this.Type = value;
    }

    
    
    public get deviceSettingsInfo(): DeviceSettingsInfo {
        return this.deviceSettingsInfo;
    }
    public set deviceSettingsInfo(value: DeviceSettingsInfo) {
        this.deviceSettingsInfo = value;
    }

    public get data(): DataUpdated {
        return this.Data;
    }
    public set data(value: DataUpdated) {
        this.Data = value;
    }

    

    public get serialNumber(): string {
        return this.SerialNumber;
    }
    public set serialNumber(value: string) {
        this.SerialNumber = value;
    }

}

@JsonObject("RequestPairing")
export class RequestPairing {

	@JsonProperty("Code", String, true)
    Code: string = "";
	@JsonProperty("Complete", Boolean, true)
	Complete: boolean = false;
	@JsonProperty("User", String, true)
	User: string = "";
	@JsonProperty("Timestamp", Number, true)
	Timestamp: number = 0;
    @JsonProperty("Device", DevicePairing, true)
	Device: DevicePairing = new DevicePairing();


	constructor() {}


    public get code(): string {
        return this.Code;
    }
    public set code(value: string) {
        this.Code = value;
    }

    public get complete(): boolean {
        return this.Complete;
    }
    public set complete(value: boolean) {
        this.Complete = value;
    }

    public get user(): string {
        return this.User;
    }
    public set user(value: string) {
        this.User = value;
    }

    public get timestamp(): number {
        return this.Timestamp;
    }
	public set timestamp(value: number) {
        this.Timestamp = value;
    }

    public get device(): DevicePairing {
        return this.Device;
    }
	public set device(value: DevicePairing) {
        this.Device = value;
    }
}
JsonObject("UserPairing")
export class UserPairing {
	@JsonProperty("Devices", [DevicePairing], true)
    Devices: DevicePairing[] = [];

    constructor() {}

    public get devices(): DevicePairing[] {
        return this.Devices;
    }
    public set devices(value: DevicePairing[]) {
        this.Devices = value;
    }
}

@JsonObject("Pairing")
export class Pairing {

	//@JsonProperty("Users", [UserPairing], true)
    Users: UserPairing[] = [];
	@JsonProperty("Requests", [RequestPairing], true)
	Requests: RequestPairing[] = [];
	
	constructor() {}


    public get users(): UserPairing[] {
        return this.Users;
    }
    public set users(value: UserPairing[]) {
        this.Users = value;
    }

    public get requests(): RequestPairing[] {
        return this.Requests;
    }
    public set name(value: RequestPairing[]) {
        this.Requests = value;
    }

}
