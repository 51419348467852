import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
   providedIn: 'root',
})
export class DataService {

    constructor(private http: HttpClient) {}

    createCustomer(data){

        return this.http.post(environment.stripe.url + '/create-customer',  data);
    }

    deleteCustomer(data){
        return this.http.post(environment.stripe.url + '/delete-customer', data);
    }

    StartSubscription(data) {
        return this.http.post(environment.stripe.url + '/create-subscription', data);
    }

    createPortal(data){

        return this.http.post(environment.stripe.url + '/create-customer-portal-session', data);
    }

    /*createCheckout(data){
        console.log(data);
        return this.http.post(environment.stripe.url + '/create-checkout-session', data);
    }*/

    /*getProducts(){
        return this.http.get(environment.stripe.url + '/config');
    }*/

    GetSubscriptions(data){

        return this.http.get(environment.stripe.url + '/subscriptions?customerID='+data.customerID);
    }

    /*searchSubscriptions(data){
        console.log('searchSubscriptions');
        console.log(data);
        return this.http.get(environment.stripe.url + '/search-subscriptions', data);
    }*/
}