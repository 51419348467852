import { JsonObject, JsonProperty, Any } from "json2typescript";

@JsonObject("Features")
export class Features {
    @JsonProperty("key", String, true)
    key = "";
    @JsonProperty("included", Boolean, true)
    included = false;
    @JsonProperty("value", Number, true)
    value = undefined;
    @JsonProperty("show", Boolean, true)
    show = true;

    constructor() {}
}

export enum SubscriptionType{
    Free = 0, Simple=1,Premium = 2, Business = 3, Pro = 4
}

@JsonObject("Subscription")
export class Subscription {

    @JsonProperty("title", String, true)
    title = "";
    @JsonProperty("description", String, true)
    description = "";
    @JsonProperty("monthly", Number, true)
    monthly = 0;
    @JsonProperty("annualy", Number, true)
    annualy = -1;
    @JsonProperty("monthlyID", String, true)
    monthlyID = "";
    @JsonProperty("annualyID", String, true)
    annualyID = "";
    @JsonProperty("productID", String, true)
    productID = "";
	@JsonProperty("trial", Number, true)
	trial = -1;
    @JsonProperty("features", [Features], true)
    features = [];
    @JsonProperty("type", Number, true)
    type = SubscriptionType.Free;
    @JsonProperty("index", Number, true)
	index = 0;

    @JsonProperty("show", Boolean, true)
	show = true;
    

    constructor() {}

}

@JsonObject("Subscriptions")
export class Subscriptions {

    @JsonProperty("subscriptions", [Subscription], true)
    subscriptions: Subscription[] = [];
   
    constructor() {}

}