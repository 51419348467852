import { JsonObject, JsonProperty } from "json2typescript";

import { Scene } from './scene.model';
import { Card } from '../card.model';
import { EventType } from './event.model';
import { HTMLOptions } from './htmlOptions.model';
import { StoryOptions } from "./storyOptions.model";
import { QuestionsSet } from "./questionsset.model";
import { Variable } from "./variables.model";
import { forEach } from "lodash";

@JsonObject("Scenario")
export class Scenario extends Card {

    @JsonProperty("Token", String, true)
    private Token: string = "";
    @JsonProperty("Scenes", [Scene], true)
    private Scenes: Scene[] = [];
    @JsonProperty("DiffusionKey", String, true)
    private DiffusionKey: string = "";
    @JsonProperty("Version", Number, true)
    private Version: number = 0; // Make a max
    @JsonProperty("VersionDiffusion", Number, true)
    private VersionDiffusion: number = -1;
    @JsonProperty("AudioKey", String, true)
    private AudioKey: string = "";
    @JsonProperty("Volume", Number, true)
    private Volume: number = 100;
    @JsonProperty("PrimaryColor", String, true)
    private PrimaryColor: string = "#ee7402";
    @JsonProperty("SecondaryColor", String, true)
    private SecondaryColor: string = "#FFFFFF";
    @JsonProperty("BackgroundColor", String, true)
    private BackgroundColor: string = "#061f44";
    @JsonProperty("AutoStart", Boolean, true)
    private AutoStart: boolean = false;
    @JsonProperty("PauseBtn", Boolean, true)
    private PauseBtn: boolean = false;
    @JsonProperty("HtmlTemplate", Boolean, true)
    private HtmlTemplate: boolean = false;
    @JsonProperty("HtmlOptions", HTMLOptions, true)
    private HtmlOptions: HTMLOptions = new HTMLOptions();
    @JsonProperty("StoryOptions", StoryOptions, true)
    private StoryOptions: StoryOptions = new StoryOptions();
    @JsonProperty("GpxKey", String, true)
    private GpxKey: string = "";
    @JsonProperty("LimitMin", Number, true)
    private LimitMin: number = 180;
    @JsonProperty("LimitMax", Number, true)
    private LimitMax: number = 180;   
    // Reset to 0 and -1 if delete from diffusion
    @JsonProperty("DiffusionState", String, true)
    private _DiffusionState: string = "";
    @JsonProperty("State", String, true)
    private State: string = "error";
    @JsonProperty("QuestionSets", [QuestionsSet], true)
    private QuestionsSets: QuestionsSet[] = [];
    @JsonProperty("DefaultLanguage", String, true)
    private DefaultLanguage: string = undefined;
    @JsonProperty("Languages", [String], true)
    private Languages: string[] = [];
    @JsonProperty("Message", String, true)
    private Message: string = 'Commencer la visite';
    @JsonProperty("Author", String, true)
    private Author: string = '';
    @JsonProperty("ScormID", Number, true)
    private ScormID: number = 0;
    @JsonProperty("Variables", [Variable], true)
    private Variables: Variable[] = [];
    
    

    private GpxUrl: string = '';

    constructor() {
        super();
        this.Key = "";
        this.Title ="";
        this.token = "";
        this.limitMin = 180;
        this.limitMax = 180;

    }
    
    
    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
	// -----------------------------------------------------------------------------------------------------
    
    public get questionsSets(): QuestionsSet[] {
        return this.QuestionsSets;
    }
    public set questionsSets(value: QuestionsSet[]) {
        this.QuestionsSets = value;
    }
    public get diffusionState(): string {
        return this._DiffusionState;
    }
    public set diffusionState(value: string) {
        this._DiffusionState = value;
    }
    public set token(value: string) {
        this.Token = value;
    }
    public get token(): string {
        return this.Token;
    }
    get scenes(): Scene[] { return this.Scenes; }
    set scenes(value: Scene[]) { this.Scenes = value; }

    get diffusionKey(): string { return this.DiffusionKey; }
    set diffusionKey(value: string) { this.DiffusionKey = value; }

    get version(): number { return this.Version; }
    set version(value: number) { 

        if (value > 1000) {
            this.Version = 1;
        } else {
            this.Version = value; 
        }

    }

    get limitMin(): number { return this.LimitMin; }
    set limitMin(value: number) { 

        if (value < 0) 
        {
            this.LimitMin = 0;
        }
        else if(value > 180)
        {
            this.LimitMin = 180; 
        }
        else
        {
            this.LimitMin = value; 
        }

    }

    get limitMax(): number { return this.LimitMax; }
    set limitMax(value: number) { 

        if (value < 0) 
        {
            this.LimitMax = 0;
        }
        else if(value > 180)
        {
            this.LimitMax = 180; 
        }
        else
        {
            this.LimitMax = value; 
        }

    }

    get versionDiffusion(): number { return this.VersionDiffusion; }
    set versionDiffusion(value: number) { this.VersionDiffusion = value; }

    get audioKey(): string { return this.AudioKey; }
    set audioKey(value: string) { this.AudioKey = value; }

    get volume(): number { return this.Volume; }
    set volume(value: number) { this.Volume = value; }

    get primaryColor(): string { return this.PrimaryColor; }
    set primaryColor(value: string) { this.PrimaryColor = value; }

    get secondaryColor(): string { return this.SecondaryColor; }
    set secondaryColor(value: string) { this.SecondaryColor = value; }

    get backgroundColor(): string { return this.BackgroundColor; }
    set backgroundColor(value: string) { this.BackgroundColor = value; }

    get htmlTemplate(): boolean { return this.HtmlTemplate; }
    set htmlTemplate(value: boolean) { this.HtmlTemplate = value; }

    get htmlOptions(): HTMLOptions { return this.HtmlOptions; }
    set htmlOptions(value: HTMLOptions) { this.HtmlOptions = value; }

    get storyOptions(): StoryOptions { return this.StoryOptions; }
    set storyOptions(value: StoryOptions) { this.StoryOptions = value; }

    get gpxKey(): string { return this.GpxKey; }
    set gpxKey(value: string) { this.GpxKey = value; }

    get state(): string { return this.State; }
    set state(value: string) { this.State = value; }

    get autoStart(): boolean { return this.AutoStart; }
    set autoStart(value: boolean) { this.AutoStart = value; }

    get pauseBtn(): boolean { return this.PauseBtn; }
    set pauseBtn(value: boolean) { this.PauseBtn = value; }
    
    public get lastUpdate(): string {
        return this.LastUpdate;
    }
    public set lastUpdate(value: string) {
        this.LastUpdate = value;
    }
    public get defaultLanguage(): string {
        return this.DefaultLanguage;
    }
    public set defaultLanguage(value: string) {
        this.DefaultLanguage = value;
    }

    get languages(): string[] { return this.Languages; }
    set languages(value: string[]) { this.Languages = value; }

    get variables(): Variable[] { return this.Variables; }
    set variables(value: Variable[]) { this.Variables = value; }

    get gpxUrl(): string { return this.GpxUrl; }
    set gpxUrl(value: string) { this.GpxUrl = value; }

    get message(): string { return this.Message; }
    set message(value: string) { this.Message = value; }

    get author(): string { return this.Author; }
    set author(value: string) { this.Author = value; }

    get scormID(): number { return this.ScormID; }
    set scormID(value: number) { this.ScormID = value; }
    // -----------------------------------------------------------------------------------------------------
	// @ Public methods
    // -----------------------------------------------------------------------------------------------------
    
    /**
     * Init by setting key and title
     * 
     * @param key 
     * @param title 
     */
    init(key: string, title: string,token : string) {
        this.Key = key;
        this.Title = title;
        this.Token = token;
    }

    /**
     * Create scene
     * 
     *  @param scene
     */
    addScene(scene: Scene, after: Scene = undefined) 
    {
        if(after==undefined){
            this.Scenes.push(scene);
            return;
        }
        let index=-1;
        for (let i = 0; i < this.Scenes.length; i++) {
            if(this.Scenes[i]==after){
                index = i+1;
            }    
        }
        let startList = this.Scenes.slice(0,index);
        startList.push(scene);
        this.Scenes = startList.concat(this.Scenes.slice(index))
    }

    /**
     * Main scene is 
     * 
     * @param scene 
     */
    mainSceneIs(scene: Scene) {

        this.Scenes.forEach((scene: Scene) => {
            scene.isMain = false;
        })

        scene.isMain = true;

    }

    /**
     * Delete scene 
     * 
     * @param scene 
     */
    delete(scene: Scene) {
		const index = this.Scenes.indexOf(scene);

		if (index > -1) {
			this.Scenes.splice(index, 1);
		}
    }
    
     /**
     * Get main scene
     * @returns Main scene
     */
    getMainScene() : Scene {
 
        let mainscene = this.scenes.filter((scene: Scene) => {
            return scene.isMain;
        });
        return mainscene[0];
 
    }

    /**
     * Increment version by one
     */
    incrementVersion() {
       this.version += 1;
    }

    /**
     * Set diffusion version
     */
    setDiffusion() {
        this.VersionDiffusion = this.Version;
    }

    updateState(_scenarioService){
        if (this.version == this.versionDiffusion || this.diffusionKey.length != 0) {
            this.diffusionState = 'published';
        }else{
            this.diffusionState = 'not_published';
        }
        
        let isReadyForDiffusion = _scenarioService.readyForDiffusion(this);
        ////console.log(isReadyForDiffusion);
        if (isReadyForDiffusion.isReady && this.diffusionState=="published" && this.version != this.versionDiffusion) {
            this.state = 'update';
        } else if (isReadyForDiffusion.isReady)  {
            this.state = 'ready';
        } else {
            this.state = 'error';
        }
    }
    /**
     * Contains media 
     * @param key 
     * 
     * @returns True if this scenario contains a media for 'key'
     */
    containsMediaFor(key: string) : boolean {

        if (this.audioKey == key) {
            return true;
        }

        if (this.Preview == key) {
            return true;
        }

        if (this.GpxKey == key) {
            return true;
        }

        for (var k = 0; k < this.scenes.length; k++) {

            let scene = this.scenes[k];

            if (scene.mediaKey == key) {
                return true;
            }

            if (scene.sceneOptions.audioKey == key) {
                return true;
            }
            if (scene.sceneOptions.obj3DKey == key) {
                return true;
            }

            for (var i = 0; i < scene.events.length; i++) {
         //       console.log(scene);
                let event = scene.events[i];
                
                if(event.iconCustom && event.iconImageKey == key)
                    return true;
                if (event.properties.Image != undefined && event.properties.Image == key) {
                    return true;
                }
                if (event.properties.Video != undefined && event.properties.Video == key) {
                    return true;
                }
                if (event.properties.Audio != undefined && event.properties.Audio == key) {
                    return true;
                }
                if (event.properties.ContentKey != undefined && event.properties.ContentKey == key) {
                    return true;
                }
                if (event.properties.Images != undefined && event.properties.Images.length > 0) {
                    for (var j = 0; j < event.properties.Images.length; j++) {
                            
                        if (event.properties.Images[j] == key) {
                            return true;
                        }
                    }
                }

                if(event.properties.Answers !== undefined && event.properties.Answers !== null && !event.properties.AnswerText){
                    for(var j = 0; j < event.properties.Answers.length; j++)
                    {
                        const answer = event.properties.Answers[j];
                        if(answer.Key == key)
                        {
                            return true;
                        }
                    }
                }

                if(
                    event.properties.AudioGood !== undefined
                    && event.properties.AudioGood !== null
                    && event.properties.AudioGood == key
                ){
                        return true;
                }
                if(
                    event.properties.AudioBad !== undefined
                    && event.properties.AudioBad !== null
                    && event.properties.AudioBad == key
                ){
                        return true;
                }
                if(event.audioProperties!==undefined)
                {
                    if(event.audioProperties.openingmedia==key)
                    {
                        return true;
                    }   
                }

                if(event.properties.Feedback !== undefined && event.properties.Feedback !== null)
                {
                    let find = false;
                    forEach(event.properties.Feedback, (feed) => {
                        if(feed.AudioKey == key)
                        {
                            return  true;
                        }

                    });

                }
            }

        };
        return false;
    }

    removeMedia(key : string){
        //console.log("REMOVE MEDIA FROM SCENARIO");
        //console.log(this.key);
        if (this.audioKey == key) {
            this.audioKey="";
            this.state="error";
        }
        for (var k = 0; k < this.scenes.length; k++) {
            let scene = this.scenes[k];

            if (scene.mediaKey == key) {
                scene.mediaKey="";
            }

            for (var i = 0; i < scene.events.length; i++) {
                let event = scene.events[i];
                switch (event.type) {
                    case EventType.Image:
                    case EventType.Consigne:
                        if (event.properties.Image == key) {
                            event.properties.Image ="";
                            this.state="error";
                        }
                        break;
                    case EventType.Diaporama:
                        for (var j = 0; j < event.properties.Images.length; j++) {
                            
                            if (event.properties.Images[j] == key) {
                                event.properties.Images[j] = "";
                                this.state="error";
                            }
                        }
                        break;
                    case EventType.Video:
                        if (event.properties.Video == key) {
                            event.properties.Video = "";
                            this.state="error";
                        }
                        break;
                    case EventType.Audio:
                        if (event.properties.Audio == key) {
                            event.properties.Audio = "";
                            this.state="error";
                        }
                        break;
                    default: 
                        break;
                }
            }

        }
        //console.log(this.state);
        return (this.state=="error");

    }

    removeTag(name : string)
    {
        let index = this.tags.indexOf(name);
        if(index == -1)
            return false;
        this.tags.splice(index);
        return true;
    }

    CreateDefaultQuestionSet(name : string,Key: string){
        if(this.questionsSets.length == 0)
        {
            const g = new QuestionsSet();
            g.name = name;
            g.clues = [];
            g.key = Key
            this.questionsSets.push(g);
        }

    }

}