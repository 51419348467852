/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { JsonObject, JsonProperty, Any } from 'json2typescript';

import { Time } from './time.model';
import { Point } from './point.model';
import { Angle } from './angle.model';
import { ConditionType, Conditions } from './condition.model';
import { Vector3 } from './vector3.model';
import { AudioProperties } from './audio.model';
import { A, M } from '@angular/cdk/keycodes';
import { Variable } from './variables.model';
import { forEach } from 'lodash';

export enum EventType {
    SceneLink = 'SceneLink',
    StoryLink = 'StoryLink',
    ContentLink = 'ContentLink',
    Text = 'Text',
    Image = 'Image',
    Mask360 = 'Mask360',
    Diaporama = 'Diaporama',
    Video = 'Video',
    Audio = 'Audio',
    WebLink = 'WebLink',
    Zone = 'Zone',
    Quiz = 'Quiz',
    QCM = 'QCM',
    QCU = 'QCU',
    QCO = 'QCO',
    QuizHotspot = 'QuizHotspot',
    Debriefing = 'Debriefing',
    Consigne = 'Consigne',
    Objet3D='Objet3D',
    Avatar='Avatar',
    HeadsetSetting='HeadsetSetting',
    Form='Form',
    SelectLanguage ='SelectLanguage',
    VoiceRecognition = 'VoiceRecognition',
    DestinationLink='DestinationLink',
    SetVariable='SetVariable',
    EndLink='EndLink',
}



export enum TitleType {
    Show = 'Show',
    Hide = 'Hide',
    Hover = 'Hover',
}

export enum TitlePosition {
    Bottom = 'Bottom',
    Top = 'Top',
    Left = 'Left',
    Right = 'Right',
}

export enum AnimationType {
    ScaleRotate = 'ScaleRotate',
    Scale = 'Scale',
    Rotate = 'Rotate'
}

export enum TransitionType {
    None = 'None',
    FadeColor = 'FadeColor',
    Blend = 'Blend',
    ZoomIn='ZoomIn',
}

@JsonObject('Event')
export class Event {
    @JsonProperty('Key', String, true)
    private Key: string = '';
    @JsonProperty('Title', String, true)
    private Title: string = '';
    @JsonProperty('Icon', String, true)
    private Icon: string = '';
    @JsonProperty('IconImageKey', String, true)
    private IconImageKey: string = '';
    @JsonProperty('IconCustom', Boolean, true)
    private IconCustom: boolean = false;
    @JsonProperty('IconColor', String, true)
    private IconColor: string = '#FFFFFF';
    @JsonProperty('IconColor2', String, true)
    private IconColor2: string = '#000000';
    @JsonProperty('IconBackground', String, true)
    private IconBackground: string = '#9e9e9e';
    @JsonProperty('Start', Time, true)
    private Start: Time = new Time();
    @JsonProperty('End', Time, true)
    private End: Time = new Time();
    @JsonProperty('Type', String, true)
    private Type: EventType = EventType.Image;
    @JsonProperty('Position', Point, true)
    private Position: Point;
    @JsonProperty('Rotation', Vector3, true)
    private Rotation: Vector3;
    @JsonProperty('Angle', Angle, true)
    private Angle: Angle;
    @JsonProperty('Size', Number, true)
    private Size: number = -1;
    @JsonProperty('Scale', Number, true)
    private Scale: number = 1;
    @JsonProperty('Display', Boolean, true)
    private Display: boolean = false;
    @JsonProperty('Hotspot', Boolean, true)
    private Hotspot: boolean = true;
    @JsonProperty('Properties', Any, true)
    private Properties: any = {};
    @JsonProperty('IsLock', Boolean, true)
    private IsLock = false;
    @JsonProperty('IsSee', Boolean, true)
    private IsSee = true;
    @JsonProperty('TitleType', String, true)
    private TitleType: TitleType = TitleType.Show;
    @JsonProperty('TitlePosition', String, true)
    private TitlePosition: TitlePosition = TitlePosition.Top;
    @JsonProperty('Animate', Boolean, true)
    private Animate = false;
    @JsonProperty('AnimateType', String, true)
    private AnimateType: AnimationType = AnimationType.ScaleRotate;
    @JsonProperty('AdvancedOptions', String, true)
    private AdvancedOptions = '';
    @JsonProperty('NbMaxOpenInScene', Number, true)
    private NbMaxOpenInScene: number = -1;
    @JsonProperty('NbMaxOpen', Number, true)
    private NbMaxOpen: number = -1;
    @JsonProperty('OpenBy', [String], true)
    private OpenBy: string[] = [];
    @JsonProperty('UsedInConditions', [String], true)
    private UsedInConditions: string[] = [];
    @JsonProperty('Conditions', Conditions, true)
    private Conditions: Conditions = new Conditions([],0);
    @JsonProperty('AudioProperties', AudioProperties, true)
    private AudioProperties: AudioProperties = new AudioProperties("",100);
    @JsonProperty('HotSpotVisible', Boolean, true)
    private HotSpotVisible = true;
    @JsonProperty('HotSpotVisibleOnOver', Boolean, true)
    private HotSpotVisibleOnOver = true;
    @JsonProperty('OpenVariable', Variable, true)
    OpenVariable: Variable = new Variable();
    @JsonProperty('CloseVariable', Variable, true)
    CloseVariable: Variable = new Variable();


    private _animationState = 'highlightDown';
    private _lockIcon = 'lock_open';
    private _seeIcon = 'visibility';

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        scale?: number,
        display?: boolean,
        titleType?: TitleType,
        titlePosition?: TitlePosition,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animatetype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        conditions: Conditions = new Conditions(),
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        this.Key = key;
        this.Title = (title===undefined)? '': title;
        this.Start = start;
        this.End = end;
        this.Position = new Point(-1, -1);
        this.Rotation = new Vector3(0,0,0);
        this.Angle = new Angle();
        this.Size = 10;
        this.Scale = scale;
        this.Display = (display!==undefined)? display : true;
        this.TitleType = (titleType!==undefined)? titleType : TitleType.Show;
        this.TitlePosition=(titlePosition !== undefined)? titlePosition : TitlePosition.Bottom;
        this.Animate = animate;
        this.iconCustom=iconCustom;
        this.iconImageKey=iconImageKey;
        this.iconBackground = '#e9e9e9';
        this.iconColor= '#ffffff';
        this.iconColor2= '#000000';
        this.AnimateType= (animatetype!== undefined)?animatetype : AnimationType.ScaleRotate;
        this.NbMaxOpenInScene = (nbMaxOpenInScene!==undefined)? nbMaxOpenInScene : 0;
        this.NbMaxOpen = (nbMaxOpen!==undefined)? nbMaxOpen : 0;
        this.OpenBy = (openBy!==undefined)? openBy : [];

        this.Conditions = (conditions!==undefined)? JSON.parse(JSON.stringify(conditions)) : new Conditions([],0);
        this.AudioProperties = (AudioProperts!==undefined)? AudioProperts : new AudioProperties("",100);
        this.HotSpotVisible = (hotspotvisible!==undefined)? hotspotvisible : true;
        this.HotSpotVisibleOnOver = (hotspotsVisibleOnOver!==undefined)? hotspotsVisibleOnOver : false;
        this.OpenVariable = (openVariable!==undefined)? JSON.parse(JSON.stringify(openVariable)) : new Variable();
        if(this.OpenVariable.actionvalue === undefined)
            this.OpenVariable.actionvalue='';
        this.CloseVariable = (closeVariable!==undefined)? JSON.parse(JSON.stringify(closeVariable)) : new Variable();
        if(this.CloseVariable.actionvalue === undefined)
            this.CloseVariable.actionvalue='';
    }

    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
    // -----------------------------------------------------------------------------------------------------
    get key(): string { return this.Key; }
    set key(value: string) { this.Key = value; }

    get title(): string { return this.Title; }
    set title(value: string) { this.Title = value; }

    get icon(): string { return this.Icon; }
    set icon(value: string) { this.Icon = value; }

    get iconImageKey(): string { return this.IconImageKey; }
    set iconImageKey(value: string) { this.IconImageKey = value; }

    get iconCustom(): boolean { return this.IconCustom; }
    set iconCustom(value: boolean) { this.IconCustom = value; }

    get iconColor(): string { return this.IconColor; }
    set iconColor(value: string) { this.IconColor = value; }

    get iconColor2(): string { return this.IconColor2; }
    set iconColor2(value: string) { this.IconColor2 = value; }

    get iconBackground(): string { return this.IconBackground; }
    set iconBackground(value: string) { this.IconBackground = value; }

    get start(): Time { return this.Start; }
    set start(value: Time) { this.Start = value; }

    get end(): Time { return this.End; }
    set end(value: Time) { this.End = value; }

    get type(): EventType { return this.Type; }
    set type(value: EventType) { this.Type = value; }

    get position(): Point { return this.Position; }
    set position(value: Point) { this.Position = value; }

    get rotation(): Vector3 { return this.Rotation; }
    set rotation(value: Vector3) { this.Rotation = value; }

    get angle(): Angle { return this.Angle; }
    set angle(value: Angle) { this.Angle = value; }

    get size(): number { return this.Size; }
    set size(value: number) { this.Size = value; }

    get scale(): number { return this.Scale; }
    set scale(value: number) { this.Scale = value; }

    get hotspot(): boolean { return this.Hotspot; }
    set hotspot(value: boolean) { this.Hotspot = value; }

    get properties(): any { return this.Properties; }
    set properties(value: any) { this.Properties = value; }

    get isLock(): boolean { return this.IsLock; }
    set isLock(value: boolean) { this.IsLock = value; }

    get display(): boolean { return this.Display; }

    get titleType(): TitleType { return this.TitleType; }
    set titleType(value: TitleType) { this.TitleType = value; }

    get titlePosition(): TitlePosition { return this.TitlePosition; }
    set titlePosition(value: TitlePosition) { this.TitlePosition = value; }

    get animate(): boolean { return this.Animate; }
    set animate(value: boolean) { this.Animate = value; }

    get animatetype(): AnimationType { return this.AnimateType; }
    set animatetype(value: AnimationType) { this.AnimateType = value; }

    get advancedOptions(): string { return this.AdvancedOptions; }
    set advancedOptions(value: string) { this.AdvancedOptions = value; }

    get animationState(): string { return this._animationState; }
    set animationState(value: string) { this._animationState = value; }

    get lockIcon(): string { return this._lockIcon; }
    set lockIcon(value: string) { this._lockIcon = value; }

    get seeIcon(): string { return this._seeIcon; }
    set seeIcon(value: string) { this._seeIcon = value; }

    get nbMaxOpenInScene(): number { return this.NbMaxOpenInScene; }
    set nbMaxOpenInScene(value: number) { this.NbMaxOpenInScene = value; }

    get nbMaxOpen(): number { return this.NbMaxOpen; }
    set nbMaxOpen(value: number) { this.NbMaxOpen = value; }

    get openBy(): string[] { return this.OpenBy; }
    set openBy(value: string[]) { this.OpenBy = value; }

    get conditions(): Conditions { return this.Conditions; }
    set conditions(value: Conditions) { this.Conditions = value; }

    get audioProperties(): AudioProperties { return this.AudioProperties; }
    set audioProperties(value: AudioProperties) { this.AudioProperties = value; }

    get usedInConditions(): string[] { return this.UsedInConditions; }
    set usedInConditions(value: string[]) { this.UsedInConditions = value; }

    get hotspotvisible(): boolean { return this.HotSpotVisible; }
    set hotspotvisible(value: boolean) { this.HotSpotVisible = value; }

    get hotspotvisibleOnOver(): boolean { return this.HotSpotVisibleOnOver; }
    set hotspotvisibleOnOver(value: boolean) { this.HotSpotVisibleOnOver = value; }

    get openVariable(): Variable { return this.OpenVariable; }
    set openVariable(value: Variable) { this.OpenVariable = value; }

    get closeVariable(): Variable { return this.CloseVariable; }
    set closeVariable(value: Variable) { this.CloseVariable = value; }

    get isSee(): boolean { return this.IsSee; }
    set isSee(value: boolean) { this.IsSee = value; }

    public inTime(currentTime): boolean{
        if(this.Start.inTimeSeconds() > currentTime || this.End.inTimeSeconds() < currentTime){
            return false;
        }
        return true;
    }


    isUsedVariable(name: string): boolean{
        if(this.OpenVariable!==undefined)
        {
            if(this.OpenVariable.name === name)
                return true;
        }
        if(this.CloseVariable!==undefined)
        {
            if(this.CloseVariable.name === name)
                return true;
        }

        for(const condition of this.Conditions.conditions)
        {
            if(condition.type === ConditionType.Variable)
            {
                const variable = condition.params['variableName'];
                if(variable === name)
                    return true;
            }
        }


        return false;
    }
}

/**
 * Scene link
 */
 export class SceneLink extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        destinationKey?: string,
        titlePosition?: TitlePosition,
        transitionType?: TransitionType,
        fadeColor?: string,
        keepOrientation?: boolean,
        timeInScene?: number,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,AudioProperts,hotspotvisible,hotspotsVisibleOnOver,openVariable,closeVariable);

        this.icon = 'es_flecheh01';
        this.type = EventType.SceneLink;
        this.properties = {
            DestinationKey: destinationKey,
            TransitionType:(transitionType !== undefined)? transitionType : TransitionType.None,
            FadeColor:(fadeColor !== undefined)? fadeColor : '0x00000',
            KeepOrientation:(keepOrientation !== undefined)? keepOrientation : false,
            timeInScene:(timeInScene!==undefined)?timeInScene:0,
        };
    }
}

/**
 * Voice Recognition
 */
 export class VoiceRecognition extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        titlePosition?: TitlePosition,
        groups? : GroupRecognition[],
        pause?: boolean,
        reduceVolume?: boolean,
        allList?: boolean,
        showName?: boolean,
        isquizz?: boolean,
        questionsSetKey?: string,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver,openVariable,closeVariable);

        this.icon = 'es_casque';
        this.type = EventType.VoiceRecognition;

        if(questionsSetKey === undefined || questionsSetKey === null || questionsSetKey === '')
        {
            questionsSetKey = 'None';
        }

        this.properties = {
            pause: (pause === undefined)? true : pause,
            reduceVolume: (reduceVolume === undefined)? true : reduceVolume,
            allList: (allList === undefined)? false : allList,
            showName: (showName === undefined)? false : showName,
            groups: (groups === undefined)? [] : groups,
            isquizz: (isquizz === undefined)? false : isquizz,
            QuestionsSetKey: questionsSetKey,

        };
    }
}


export class StoryLink extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        destinationKey?: string,
        titlePosition?: TitlePosition,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions, AudioProperts,
            hotspotvisible,hotspotsVisibleOnOver,openVariable,closeVariable);

        this.icon = 'es_lien01';
        this.type = EventType.StoryLink;
        this.properties = {
            DestinationKey: destinationKey
        };
    }

}

export class EndLink extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        titlePosition?: TitlePosition,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions, AudioProperts,
            hotspotvisible,hotspotsVisibleOnOver,openVariable,closeVariable);

        this.icon = 'es_pin03';
        this.type = EventType.EndLink;

    }

}

export class DestinationLink extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        titlePosition?: TitlePosition,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions, AudioProperts,
            hotspotvisible,hotspotsVisibleOnOver,openVariable,closeVariable);

        this.icon = 'es_verrou';
        this.type = EventType.DestinationLink;

    }

}

export class ContentLink extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        contentKey?: string,
        open?: boolean,
        titlePosition?: TitlePosition,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver,openVariable,closeVariable);

        this.icon = 'es_depliant';
        this.type = EventType.ContentLink;
        this.properties = {
            ContentKey: contentKey,
            Open: (open === undefined)? true : open
        };
    }

}


/**
 * Text
 */
export class Text extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        textHeader?: string,
        text?: string,
        alignement?: string,
        isFullScreen?: boolean,
        textColor?: string,
        backgroundColor?: string,
        scale: number=1.0,
        style: string='Regular',
        audio?: string,
        volume?: number,
        titlePosition?: TitlePosition,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        autoQuit?: boolean,
        maxwidth?: number,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver,openVariable,closeVariable);

        this.hotspot = true;
        this.icon = 'es_texte_02';
        this.type = EventType.Text;
        this.properties = {
            Audio: (audio===undefined)? '':audio,
            Volume: (volume===undefined)? 100:volume,
            TextHeader: textHeader,
            Text: text,
            Alignement: alignement,
            IsFullScreen: isFullScreen,
            TextColor: textColor,
            BackgroundColor: backgroundColor,
            Scale:scale,
            FontStyle: style,
            AutoQuit: (autoQuit === undefined)? false : autoQuit,
            Maxwidth: (maxwidth === undefined)? 1 : maxwidth
        };
    }
}

/**
 * Image
 */
export class Image extends Event {


    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        image?: string,
        isFullScreen?: boolean,
        scale: number=1.0,
        titlePosition?: TitlePosition,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver,openVariable,closeVariable);

        this.icon = 'es_image';
        this.type = EventType.Image;

        this.properties = {
            Image: image,
            IsFullScreen: isFullScreen,
            Scale:scale
        };
    }
}

export class Mask360 extends Event {


    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        image?: string,
        titlePosition?: TitlePosition,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver,openVariable,closeVariable);

        this.icon = 'es_image';
        this.type = EventType.Mask360;

        this.properties = {
            Image: image,
        };
    }
}

export class Objet3D extends Event {


    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        content?: string,
        scale: number=1.0,
        titlePosition?: TitlePosition,
        positionX: number=0.0,
        positionY: number=0.0,
        positionZ: number=0.0,
        rotateSpeed: number=0.0,
        rotateX: boolean=false,
        rotateY: boolean=true,
        rotateZ: boolean=false,
        rotationX: number=0.0,
        rotationY: number=0.0,
        rotationZ: number=0.0,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver, openVariable,closeVariable);

        this.icon = 'es_3D01';
        //this.iconBackground = '#000000';
        //this.iconColor= '#ffffff';
        this.type = EventType.Objet3D;

        this.properties = {
            ContentKey: content,
            Scale:scale,
            PositionX : positionX,
            PositionY : positionY,
            PositionZ : positionZ,
            RotateSpeed:rotateSpeed,
            RotateX:rotateX,
            RotateY:rotateY,
            RotateZ:rotateZ,
            RotationX:rotationX,
            RotationY:rotationY,
            RotationZ:rotationZ
        };
    }
}

export class Avatar extends Event {


    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        content?: string,
        audiokey?: string,
        volume?: number,
        scale: number=1.0,
        titlePosition?: TitlePosition,
        positionX: number=0.0,
        positionY: number=-1.6,
        positionZ: number=2.0,
        rotationX: number=0.0,
        rotationY: number=0.0,
        rotationZ: number=0.0,
        eventlinked?: string,
        delay?: number,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver, openVariable,closeVariable);

        this.icon = 'es_tete01';
        //this.iconBackground = '#000000';
        //this.iconColor= '#ffffff';
        this.type = EventType.Avatar;

        this.properties = {
            ContentKey: content,
            Audio:audiokey,
            Scale:scale,
            PositionX : positionX,
            PositionY : positionY,
            PositionZ : positionZ,
            RotationX:rotationX,
            RotationY:rotationY,
            RotationZ:rotationZ,
            EventLinked:(eventlinked===undefined)?'':eventlinked,
            Delay:(delay===undefined)?0:delay,
            Volume: (volume===undefined)? 100:volume,
        };
    }
}

/**
 * Diaporama
 */
export class Diaporama extends Event {


    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        images?: Array<string>,
        isFullScreen?: boolean,
        scale: number=1.0,
        startFullScreen: boolean=false,
        auto: boolean=false,
        btn: boolean=true,
        num: boolean=false,
        time: number=15,
        titlePosition?: TitlePosition,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
             AudioProperts,hotspotvisible,hotspotsVisibleOnOver,openVariable,closeVariable);


        this.icon = 'es_diapositive';
        this.type = EventType.Diaporama;

        const imgs = [];
        if(images!== undefined){
            for(const img of images){
                imgs.push(img);
            }
        }
        this.properties = {
            Images: imgs,
            Size: (images === undefined)? 0 : images.length,
            IsFullScreen: isFullScreen,
            StartFullScreen: startFullScreen,
            Scale:scale,
            Auto: auto,
            ShowBtn: btn,
            ShowNum: num,
            Time: time,

        };

    }
}

/**
 * Video
 */
export class Video extends Event {


    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        video?: string,
        loop?: boolean,
        volume?: number,
        isFullScreen?: boolean,
        scale: number=1.0,
        titlePosition?: TitlePosition,
        webFull?: boolean,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
             AudioProperts,hotspotvisible,hotspotsVisibleOnOver, openVariable,closeVariable);

        this.icon = 'es_video';
        this.type = EventType.Video;


        this.properties = {
            Video: video,
            Volume: (volume===undefined)? 100 : volume,
            Loop: loop,
            IsFullScreen: isFullScreen,
            Scale:scale,
            WebFull: (undefined === webFull)? false : webFull
        };
    }
}

/**
 * Audio
 */
export class Audio extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        audio?: string,
        volume?: number,
        loop?: boolean,
        autoPause?: boolean,
        titlePosition?: TitlePosition,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
             AudioProperts,hotspotvisible,hotspotsVisibleOnOver, openVariable,closeVariable);

        this.icon = 'es_son';
        this.type = EventType.Audio;
        this.properties = {
            Audio: (audio===undefined)? '':audio,
            Volume: (volume===undefined)? 100:volume,
            Loop: loop,
            AutoPause: (autoPause===undefined)? true : autoPause
        };
    }
}

/**
 * Web link
 */
export class WebLink extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        url?: string,
        iframe?: boolean,
        safeUrl?: string,
        titlePosition?: TitlePosition,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver, openVariable,closeVariable);

        this.icon = 'es_Web01';
        this.type = EventType.WebLink;
        this.properties = {
            Url: url,
            Iframe: (iframe === undefined)? false : iframe,
            SafeUrl: (safeUrl === undefined)? '' : safeUrl
        };
    }
}

/**
 * Zone
 */
export class Zone extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        radius?: number,
        titlePosition?: TitlePosition,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable


    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver,  openVariable,closeVariable);

        this.hotspot = false;
        this.icon = 'es_cible01';
        this.type = EventType.Zone;
        this.properties = {
            Radius: radius
        };
    }
}

/**
 * Quiz
 */
export class QuizAnswer {
    @JsonProperty('Text', String, true)
    Text: string = '';

    @JsonProperty('Key', String, true)
    Key: string = '';

    @JsonProperty('Points', Number, true)
    Points: number = 0;

    @JsonProperty('Checked', Boolean, true)
    Checked: boolean = false;

    @JsonProperty('Variable', Variable, true)
    Variable: Variable = new Variable();
}

export class Quiz extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        type?: EventType,
        question?: string,
        answers?: QuizAnswer[],
        feedbackGood?: string,
        audioGood?: string,
        feedbackBad?: string,
        audioBad?: string,
        time?: number,
        isFullScreen?: boolean,
        image? : string,
        audio?: string,
        volume?: number,
        titlePosition?: TitlePosition,
        cluesHelpKey?: string,
        cluesWinKey?: string,
        cluesWinBool?: boolean,
        answerText?: boolean,
        correct?: boolean,
        autoQuit?: boolean,
        zoom?: boolean,
        groups?: GroupRecognition[],
        conditions?: Conditions,
        idScorm?: number,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
        ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver, openVariable,closeVariable);

        this.icon = 'es_interrogation02';
        this.type = type;


        const tmp = [];
        if(answers!== undefined){
            for(const answer of answers){
                const a = new QuizAnswer();
                a.Checked = answer.Checked;
                a.Points = answer.Points;
                a.Text = answer.Text;
                a.Key = (answer.Key === undefined)?'':answer.Key;
                if(answer.Variable===undefined)
                {
                    a.Variable = new Variable();
                }
                else
                {
                    const newVar=new Variable();
                    newVar.initialvalue=answer.Variable.initialvalue;
                    newVar.name=answer.Variable.name;
                    newVar.type=answer.Variable.type;
                    newVar.stringvalues=answer.Variable.stringvalues;
                    newVar.actionvalue=answer.Variable.actionvalue;
                    if(newVar.stringvalues===undefined)
                    {
                        newVar.stringvalues=[];
                    }
                    if(newVar.actionvalue===undefined)
                    {
                        newVar.actionvalue='';
                    }
                    a.Variable=newVar;
                }
                tmp.push(a);
            }
        }
        //console.log(tmp);
        this.properties = {
            Audio: (audio===undefined)? '':audio,
            Volume: (volume===undefined)? 100:volume,
            Question: question,
            Answers: tmp,
            FeedbackGood: feedbackGood,
            AudioGood: (audioGood === undefined)?'':audioGood,
            FeedbackBad: feedbackBad,
            AudioBad: (audioBad === undefined)?'':audioBad,
            Time: time,
            IsFullScreen: isFullScreen,
            Image: (image === undefined)? '' : image,
            CluesHelpKey: (cluesHelpKey === undefined)? 'None' : cluesHelpKey,
            CluesWinKey: (cluesWinKey === undefined)? 'None' : cluesWinKey,
            CluesWinBool: (cluesWinBool === undefined)? false : cluesWinBool,
            AnswerText: (answerText === undefined)? true : answerText,
            Correct: (correct === undefined)? true : correct,
            AutoQuit: (autoQuit === undefined)? false : autoQuit,
            Zoom: (zoom === undefined)? true : zoom,
            groups: (groups === undefined)? [] : groups,
            idScorm: (idScorm === undefined)? -1 : idScorm
        };
    }

}
export class QCO extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        type?: EventType,
        question?: string,
        answer?: string,
        feedbackGood?: string,
        audioGood?: string,
        feedbackBad?: string,
        audioBad?: string,
        time?: number,
        isFullScreen?: boolean,
        image?: string,
        goodPoints?: number,
        badPoints?: number,
        audio?: string,
        volume?: number,
        titlePosition?: TitlePosition,
        cluesHelpKey?: string,
        cluesWinKey?: string,
        cluesWinBool?: boolean,
        correct?: boolean,
        autoQuit?: boolean,
        zoom?: boolean,
        groups?: GroupRecognition[],
        conditions?: Conditions,
        idScorm?: number,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver, openVariable,closeVariable);

        this.icon = 'es_interrogation02';
        this.type = type;

        //console.log(tmp);
        this.properties = {
            Audio: (audio===undefined)? '':audio,
            Volume: (volume===undefined)? 100:volume,
            Question: question,
            AnswerText: answer,
            GoodPoints: goodPoints,
            BadPoints: badPoints,
            FeedbackGood: feedbackGood,
            AudioGood: (audioGood === undefined)?'':audioGood,
            FeedbackBad: feedbackBad,
            AudioBad: (audioBad === undefined)?'':audioBad,
            Time: time,
            IsFullScreen: isFullScreen,
            Image: (image === undefined)? '' : image,
            CluesHelpKey: (cluesHelpKey === undefined)? 'None' : cluesHelpKey,
            CluesWinKey: (cluesWinKey === undefined)? 'None' : cluesWinKey,
            CluesWinBool: (cluesWinBool === undefined)? false : cluesWinBool,
            Correct: (correct === undefined)? true : correct,
            AutoQuit: (autoQuit === undefined)? false : autoQuit,
            Zoom: (zoom === undefined)? true : zoom,
            groups: (groups === undefined)? [] : groups,
            idScorm: (idScorm === undefined)? -1 : idScorm
        };
    }
}

/**
 * Quiz Hotspot
 */
export class QuizHotspotAnswer {
    Position: Point = new Point(-1, -1);
    Angle: Angle = new Angle();
    Points: number = 0;
    Checked: boolean = false;
    ImageKey: string = '';
    ImageSize: number = 1;
    Variable: Variable = new Variable();
}

export class QuizHotspot extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        question?: string,
        hotspotAnswers?: QuizHotspotAnswer[],
        feedbackGood?: string,
        audioGood?: string,
        feedbackBad?: string,
        audioBad?: string,
        time?: number,
        diplayAnswers?: boolean,
        audio?: string,
        volume?: number,
        titlePosition?: TitlePosition,
        cluesHelpKey?: string,
        cluesWinKey?: string,
        cluesWinBool?: boolean,
        correct?: boolean,
        autoQuit?: boolean,
        groups?: GroupRecognition[],
        conditions?: Conditions,
        idScorm?: number,
        AudioProperts?: AudioProperties,
        respectOrder?: boolean,
        multiChoice?: boolean,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver, openVariable,closeVariable);

        this.icon = 'es_interrogation02';
        this.type = EventType.QuizHotspot;

        const tmp = [];
        if(hotspotAnswers!== undefined){
            for(const hotspotAnswer of hotspotAnswers){
                const a = new QuizHotspotAnswer();
                a.Angle = new Angle();
                a.Angle.alpha = hotspotAnswer.Angle.alpha;
                a.Angle.beta = hotspotAnswer.Angle.beta;
                a.Checked = hotspotAnswer.Checked;
                a.ImageKey = hotspotAnswer.ImageKey;
                a.ImageSize = hotspotAnswer.ImageSize;
                a.Points = hotspotAnswer.Points;
                a.Position = new Point();
                a.Position.x = hotspotAnswer.Position.x;
                a.Position.y = hotspotAnswer.Position.y;
                if(hotspotAnswer.Variable===undefined)
                {
                    a.Variable = new Variable();
                }
                else
                {
                    const newVar=new Variable();
                    newVar.initialvalue=hotspotAnswer.Variable.initialvalue;
                    newVar.name=hotspotAnswer.Variable.name;
                    newVar.type=hotspotAnswer.Variable.type;
                    newVar.stringvalues=hotspotAnswer.Variable.stringvalues;
                    if(newVar.stringvalues===undefined)
                    {
                        newVar.stringvalues=[];
                    }
                    newVar.actionvalue=hotspotAnswer.Variable.actionvalue;
                    if(newVar.actionvalue===undefined)
                    {
                        newVar.actionvalue='';
                    }
                    a.Variable=newVar;
                }
                tmp.push(a);
            }
        }
        this.properties = {
            Audio: (audio===undefined)? '':audio,
            Volume: (volume===undefined)? 100:volume,
            Question: question,
            HotspotAnswers: tmp,
            FeedbackGood: feedbackGood,
            AudioGood: (audioGood === undefined)?'':audioGood,
            FeedbackBad: feedbackBad,
            AudioBad: (audioBad === undefined)?'':audioBad,
            Time: time,
            Answer: diplayAnswers,
            CluesHelpKey: (cluesHelpKey === undefined)? 'None' : cluesHelpKey,
            CluesWinKey: (cluesWinKey === undefined)? 'None' : cluesWinKey,
            CluesWinBool: (cluesWinBool === undefined)? false : cluesWinBool,
            Correct: (correct === undefined)? true : correct,
            AutoQuit: (autoQuit === undefined)? false : autoQuit,
            groups: (groups === undefined)? [] : groups,
            idScorm: (idScorm === undefined)? -1 : idScorm,
            respectOrder: (respectOrder === undefined)? false : respectOrder,
            multiChoice: (multiChoice === undefined)? true : multiChoice
        };
    }

}
/**
 * Debriefing
 */
export class Debriefing extends Event {
    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        feedback?: Feedback[],
        isFullScreen?: boolean,
        description?: string,
        titre?: string,
        storyEnd: boolean = false,
        titlePosition?: TitlePosition,
        questionsSetKey?: string,
        displayType?:number,
        groups? : GroupRecognition[],
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver, openVariable,closeVariable);

        this.icon = 'es_bulle';
        this.type = EventType.Debriefing;
        let tmp = [];
        if(feedback === undefined){
            tmp = new Feedback[0]();
        }
        if(feedback.length === 0){
            let f = new Feedback();
            f.AudioKey='';
            f.AudioVolume=100;
            f.Description='';
            f.PourcentMin=0;
            f.PourcentMax=32;
            tmp.push(f);
            f = new Feedback();
            f.AudioKey='';
            f.AudioVolume=100;
            f.Description='';
            f.PourcentMin=33;
            f.PourcentMax=65;
            tmp.push(f);
            f = new Feedback();
            f.AudioKey='';
            f.AudioVolume=100;
            f.Description='';
            f.PourcentMin=66;
            f.PourcentMax=100;
            tmp.push(f);
        }else{
            for(const feed of feedback){
                const f = new Feedback();
                f.AudioKey= (feed.AudioKey === undefined)? '' : feed.AudioKey;
                f.AudioVolume= (feed.AudioVolume === undefined)? 100 :feed.AudioVolume;
                f.Description=feed.Description;
                f.PourcentMin=feed.PourcentMin;
                f.PourcentMax=feed.PourcentMax;
                tmp.push(f);
                //tmp.push(jsonConvert.deserializeObject(jsonConvert.serializeObject(feedback[i]),Feedback));
            }
        }

        if(questionsSetKey === undefined || questionsSetKey === null || questionsSetKey === '')
        {
            questionsSetKey = 'None';
        }

        this.properties = {
            Feedback : tmp,
            groups: (groups === undefined)? [] : groups,
            IsFullScreen: isFullScreen,
            Text: description,
            Title: (title===undefined)? '': titre,
            StoryEnd : storyEnd,
            QuestionsSetKey: questionsSetKey,
            DisplayType:displayType,
        };
    }
}

/**
 * Consigne
 */
export class Consigne extends Event {


    constructor(
        key?: string,
        titlePoi?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        title?: string,
        text?: string,
        image?: string,
        button?: string,
        isFullScreen?: boolean,
        scale: number=1.0,
        audio?: string,
        volume?: number,
        titlePosition?: TitlePosition,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, titlePoi, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen, openBy,conditions,
            AudioProperts,hotspotvisible,hotspotsVisibleOnOver,openVariable,closeVariable);

        this.icon = 'es_document';
        this.type = EventType.Consigne;

        this.properties = {
            Audio: (audio===undefined)? '':audio,
            Volume: (volume===undefined)? 100:volume,
            Title : (title===undefined)? '': title,
            Text : text,
            Image: image,
            Button: button? button : 'Continuer',
            IsFullScreen: isFullScreen,
            Scale:scale
        };
    }
}

/**
 * Consigne
 */
 export class SelectLanguage extends Event {


    constructor(
        key?: string,
        titlePoi?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?: string[],
        title?: string,
        unique?: boolean,
        langs?: string[],
        lang?: string,
        image?: string,
        isFullScreen?: boolean,
        scale: number=1.0,
        audio?: string,
        volume?: number,
        titlePosition?: TitlePosition,
        conditions?: Conditions,
        AudioProperts?: AudioProperties,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ) {
        super(key, titlePoi, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,
            conditions,AudioProperts,hotspotvisible,hotspotsVisibleOnOver,openVariable,closeVariable);
        this.icon = 'es_pin03';
        this.type = EventType.SelectLanguage;

        this.properties = {
            Audio: (audio===undefined)? '':audio,
            Volume: (volume===undefined)? 100:volume,
            Title : (title===undefined)? '': title,
            Unique : unique,
            Langs : (langs === undefined )? [] : langs,
            Lang : (lang === undefined )? '' : lang,
            //Text : text,
            Image: image,
            //Button: button,
            IsFullScreen: isFullScreen,
            Scale:scale
        };
    }
}

export class Feedback{
    PourcentMin: number = 0;
    PourcentMax: number = 100;
    Description: string = '';
    AudioKey: string = '';
    AudioVolume: number = 100;

    constructor(){
        this.PourcentMin = 0;
        this.PourcentMax = 100;
        this.Description = '';
        this.AudioKey = '';
        this.AudioVolume = 100;
    }
}

export class GroupRecognition{

    title: string;
    words: string[];
    events: string[];
    points?:number;

    constructor(){
        this.title = '';
        this.words = [];
        this.events = [];
        this.points=0;
    }
}

export class SetVariableEvent extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?:string,
        animate?: boolean,
        animationtype?:AnimationType,
        nbMaxOpenInScene?:number,
        nbMaxOpen?:number,
        openBy?:string[],
        titlePosition?: TitlePosition,
        hotspotvisible?: boolean,
        hotspotsVisibleOnOver?: boolean,
        conditions?: Conditions,
        openVariable?: Variable,
        closeVariable?: Variable,
        variables?: Variable[]
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,
            undefined,hotspotvisible,hotspotsVisibleOnOver,openVariable,closeVariable);

        this.icon = "es_tools";
        this.type = EventType.SetVariable;

        const tmp = [];
        if(variables!=undefined)
        {
            for(let i=0; i< variables.length; i++)
            {

                const newVar=new Variable();
                    newVar.initialvalue=variables[i].initialvalue;
                    newVar.name=variables[i].name;
                    newVar.type=variables[i].type;
                    newVar.stringvalues=variables[i].stringvalues;
                    newVar.actionvalue=variables[i].actionvalue;
                    if(newVar.stringvalues===undefined)
                    {
                        newVar.stringvalues=[];
                    }
                    if(newVar.actionvalue===undefined)
                    {
                        newVar.actionvalue='';
                    }

                tmp.push(newVar);
            }
        }
        this.properties = {
            Variables: tmp
        };
    }
}

export const eventTypeArray= {
    'SceneLink': SceneLink,
    'StoryLink': StoryLink,
    'ContentLink': ContentLink,
    'Text': Text,
    'Image': Image,
    'Diaporama': Diaporama,
    'Video': Video,
    'Audio': Audio,
    'WebLink': WebLink,
    'Zone': Zone,
    'Quiz': Quiz,
    'QCO': QCO,
    'QuizHotspot': QuizHotspot,
    'Debriefing': Debriefing,
    'Consigne': Consigne,
    'Objet3D': Objet3D,
    //'HeadsetSetting': HeadsetSetting,
    //'Form': FormEvent,
    'SelectLanguage': SelectLanguage,
    'VoiceRecognition': VoiceRecognition,
    'SetVariable': SetVariableEvent,
};
