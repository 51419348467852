import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Time")
export class Time {



	@JsonProperty("Seconds", Number, true)
	private Seconds: number;

	constructor(second: number = 0) {

		this.setSeconds(second);
	}





	// -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
    // -----------------------------------------------------------------------------------------------------
    get hour(): number {
		return Math.floor(this.Seconds / 3600);
	}


	get minute(): number {
		return Math.floor((this.Seconds - (Math.floor(this.Seconds / 3600) * 3600)) / 60);
	}

	get second(): number {
		return this.Seconds;
	}

    set second(value: number) {
		this.Seconds = value;
	}


	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
    // -----------------------------------------------------------------------------------------------------

	/**
	 * Format
	 *
	 * @param time
	 *
	 * @returns ':0T' or ':TT'
	 */
	private format(time: number) : string {
		time = Math.floor(time);
		if (time < 10) {
			return '0' + time.toString();
		} else {
			return time.toString();
		}
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
    // -----------------------------------------------------------------------------------------------------

	/**
	 * In seconds
	 *
	 * @returns Time in seconds
	 */
	inSeconds() : number {
		//this.Seconds = this.Second + this.Minute * 60 + this.Hour * 3600;
		return this.Seconds;
	}
	/**
	 * In Time seconds
	 *
	 * @returns Time in seconds
	 */
	inTimeSeconds() : number {
		return this.Seconds;
	}

	/**
	 * Set time by seconds
	 *
	 * @param seconds
	 */
	setSeconds(seconds: number) {

		this.Seconds = seconds;

		// console.log("Math floor may cause accuracy problem");
		// seconds = Math.floor(seconds);
		/*this.Hour = Math.floor(seconds / 3600);
		this.Minute = Math.floor((seconds - (this.Hour * 3600)) / 60);
		this.Second = seconds - (this.Minute * 60) - (this.Hour * 3600);*/
	}

	/**
	 * Time format
	 *
	 * @returns Time with format MM:SS
	 */
	getTimeFormat(): string {

		if (this.second < 60) {
			return "00:" + this.format(this.second);
			// return "00:00:" + this.format(time);
		} else if (this.second < 3600) {
			let minutes = Math.floor(this.second / 60);
			let secondes = this.second - (minutes * 60);
			return this.format(minutes) + ":" + this.format(secondes);
			// return "00:" + this.format(minutes) + ":" + this.format(secondes);
		} else if (this.second < 86400) {
            let hours = Math.floor(this.second / 3600);
            let minutes = Math.floor((this.second - (hours * 3600)) / 60);
            let secondes = this.second - (minutes * 60) - (hours * 3600);
            return this.format(hours) + ":" + this.format(minutes) + ":" + this.format(secondes);
		} else {
            console.error("Time format error");
            console.log(this.second);
            return 'Time format error';
        }

	}

    fromFormatString(time: string): number {
        let timeArray = time.split(':');
        if (timeArray.length === 2) {
            this.Seconds = parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
        } else if (timeArray.length === 3) {
            this.Seconds = parseInt(timeArray[0]) * 3600 + parseInt(timeArray[1]) * 60 + parseInt(timeArray[2]);
        } else {
            console.error("Time format error");
        }
        return this.Seconds;
    }

}